<!-- @format -->

<template>
  <div class="flex justify-center items-center bg-black">
    <video :src="src" controls :width="400" :height="100"></video>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  width: {
    type: Number,
    default: 640,
  },
  height: {
    type: Number,
    default: 360,
  },
});

console.log("herea reh te props ========>", props);
</script>

<style scoped></style>
